// Connections to spot

export const category = ["global", "bribe", "health", "terminal"];

export const healtList = [
  {
    title: "GOAT_Sentient",
    btn: false,
    count: 3,
  },
  {
    title: "GOAT_Sentient",
    btn: true,
    count: 3,
  },
  {
    title: "GOAT_Sentient",
    btn: true,
    count: 3,
  },
  {
    title: "GOAT_Sentient",
    btn: false,
    count: 3,
  },
];
