const LOGOS = {
  logo: "/playai/logos/Glasseslogo.png",
  logo_solana: "/playai/logos/solana.svg",
};

const ICONS = {
  icon_copy: "/playai/icons/copy.svg",
  icon_hart: "/playai/icons/hart.svg",
  icon_hartfill: "/playai/icons/hartfill.svg",
  icon_Profile: "/playai/icons/Icon.png",
};

const IMAGES = {};

export const assets = {
  ICONS,
  LOGOS,
  IMAGES,
};
