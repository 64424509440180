import { Flex, Image, Stack, Text, WrapItem } from "@chakra-ui/react";
import Btn from "../../Buttons/Btn";
import { assets } from "../../../assets";
import { healtList } from "../../../DB";
import { useAppCtx } from "../../../contexts/app.context";
import CLIPARTS from "../../../assets/clipart";
import { useServerGame } from "../../../../../hooks/serverGame";
import { Id } from "../../../../../convex/_generated/dataModel";
const HealthBox = ({ worldId }: { worldId?: Id<"worlds"> }) => {
  const game = useServerGame(worldId);

  const playerDescriptions = game?.playerDescriptions;

  const playerList = Array.from(game?.world.players.values() ?? [])
    .filter((player) => !player.human)
    .map((player) => ({
      title: playerDescriptions?.get(player.id)?.name,
      character_id: playerDescriptions?.get(player.id)?.character_id,
      btn: false,
      count: 3,
    }));
  const healthList = playerList || healtList;
  return (
    <Stack gap={4}>
      {healthList?.map((list) => <HealthItem data={list} />)}
    </Stack>
  );
};

const HealthItem = ({ data }: { data: any }) => {
  const { setSelectedReviveItem } = useAppCtx();

  return (
    <Flex justifyContent={"space-between"} align={"center"}>
      <Flex align={"center"} gap={2}>
        <WrapItem>
          {/* <Image src={assets.ICONS.icon_Profile} /> */}
          <Image
            src={`${process.env.REACT_APP_ASSETS_BUCKET}${data?.character_id}/display_picture.jpg`}
            borderRadius={"50%"}
            width={20}
            height={20}
          />
        </WrapItem>
        <Stack gap={2} fontFamily={"secondary"}>
          <Text fontSize={"md"} fontWeight={500} lineHeight={"100%"}>
            {data?.title}
          </Text>
          <Flex gap={1.5}>
            <CLIPARTS.Hart isActive />
            <CLIPARTS.Hart isActive />
            <CLIPARTS.Hart />
            <CLIPARTS.Hart />
            <CLIPARTS.Hart />
            <CLIPARTS.Hart />
          </Flex>
        </Stack>
      </Flex>
      <Stack>
        <Stack align={"center"} justify={"center"}>
          {data?.btn ? (
            <Btn
              px={2}
              fontSize={"12px"}
              // color={"rgba(29, 155, 240, 1)"}
              cta={() => setSelectedReviveItem(data)}
            >
              Revive
            </Btn>
          ) : null}
        </Stack>
      </Stack>
    </Flex>
  );
};

export default HealthBox;
