import { Stack, Text } from "@chakra-ui/react";
import { useServerGame } from "../../../../../hooks/serverGame";
import { Id } from "../../../../../convex/_generated/dataModel";
import { GameId } from "../../../../../convex/aiTown/ids";
import { useQuery } from "convex/react";
import { api } from "../../../../../convex/_generated/api";

const TerminalBox = ({ worldId }: { worldId?: Id<"worlds"> }) => {
  const game = useServerGame(worldId);
  const playerDescriptions = game?.playerDescriptions;

  // Get memories for all players by creating an array of player IDs first
  const playerIds = Array.from(playerDescriptions?.keys() ?? []);

  // Single useQuery call with all playerIds
  const allMemories = useQuery(api.agent.memory.getWorldUIMemories, {
    worldId: worldId as Id<"worlds">,
  });

  // Sort all memories by creation time descending
  const sortedMemories =
    allMemories?.sort((a, b) => b._creationTime - a._creationTime) ?? [];

  return (
    <Stack gap={6}>
      <Stack>
        <Stack align={"start"} gap={2}>
          {sortedMemories.map((memory, index) => {
            const player = playerDescriptions?.get(
              memory.playerId as GameId<"players">
            );
            return (
              <Text key={index} fontSize={"sm"}>
                <Text as="span" fontWeight="bold" textTransform="uppercase">
                  {player?.name}:{" "}
                </Text>
                {memory.description}
              </Text>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TerminalBox;
