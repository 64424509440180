import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
  WrapItem,
} from "@chakra-ui/react";
import { ChevronsRight } from "lucide-react";
import { motion } from "framer-motion";
import { useQuery, useMutation } from "convex/react";
import { assets } from "../../assets";
import TabBtn from "../Buttons/TabBtn";
import { category } from "../../DB";
import { brandColors } from "../../theme/app.theme";
import AgentTip from "./Global/AgentTip";
import { useAppCtx } from "../../contexts/app.context";
import GlobalChatBox from "./Global/GlobalChatBox";
import TerminalBox from "./Terminal/TerminalBox";
import HealthBox from "./Health/HealthBox";
import { useState } from "react";
import InputTeb from "../Input/Input";
import Btn from "../Buttons/Btn";
import { trimWords } from "../../lib/app.fun";
import Revive from "./Health/Revive";
import { useAppKitAccount, useDisconnect } from "@reown/appkit/react";
import EVMConnectBTN from "../Buttons/EVMConnectBTN";
import BriveBox from "./Bribe/BriveBox";
import { TownContext } from "../../../../contexts/TownContext";
import { Id } from "../../../../convex/_generated/dataModel";
import { api } from "../../../../convex/_generated/api";

const RightView = ({ rightView, setRightView }: any) => {
  const { address, isConnected } = useAppKitAccount();
  const context = useContext(TownContext);
  if (!context) {
    throw new Error("TownContext must be used within a TownProvider");
  }
  const { town_id } = context;
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();
  const {
    showTipAgent,
    setsTipAgent,
    sectionType,
    setSectionType,
    selectedRevaiveItem,
    setGlobalMessages,
    globalMessages,
  } = useAppCtx();

  const [inputvalue, setInputValue] = useState("");
  const cryptoLiveChatMessages = useQuery(api.world.cryptoLiveChatMessages, {
    worldId: town_id as Id<"worlds">,
  });

  useEffect(() => {
    if (cryptoLiveChatMessages) {
      const formattedMessages = cryptoLiveChatMessages.map((msg) => ({
        name: msg.author,
        message: msg.text,
      }));
      setGlobalMessages(formattedMessages);
    }
  }, [cryptoLiveChatMessages, setGlobalMessages]);

  const sendCryptoLiveChat = useMutation(api.world.sendCryptoLiveChat);
  const handleSend = () => {
    if (inputvalue.trim()) {
      sendCryptoLiveChat({
        worldId: town_id as Id<"worlds">,
        author: address ?? "",
        text: inputvalue,
      });
      setInputValue(""); // Clear the input after sending
    }
  };

  return (
    <Flex
      border={`0.5px solid ${brandColors.stroke}`}
      gap={4}
      bg={"brand.primary"}
      w={window.innerWidth < 768 ? "100%" : "400px"}
      minW={window.innerWidth < 768 ? "100%" : "350px"}
      maxW={"100vw"}
      h={"100%"}
      flexDir={"column"}
      //zIndex={1000}
    >
      <Flex
        w={"100%"}
        justify={"space-between"}
        align={"center"}
        bg={"brand.primary700"}
        p={4}
        py={5}
        boxShadow={` 3px 3px 0px 0px ${brandColors.stroke};`}
      >
        <div className="flex items-center gap-2 m-2">
          {rightView && (
            <motion.button
              className=""
              onClick={() => setRightView(!rightView)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <ChevronsRight size={24} />
            </motion.button>
          )}
          <Image src={assets.LOGOS.logo} w={"80px"} />
          <div className="flex items-center">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => navigate("/")}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text fontSize="xs" color="gray.500" ml={1}>
                powered by
              </Text>
              <Image
                src="/4Wall_Logo_Package/black-transparent.png"
                w={"70px"}
                ml={1}
              />
            </motion.button>
          </div>
        </div>

        {isConnected ? (
          <Popover>
            <PopoverTrigger>
              <WrapItem cursor={"pointer"}>
                <Image src={assets.ICONS.icon_Profile} />

                {/* <Avatar name="Dan Abrahmov" src={walletIcon} /> */}
              </WrapItem>
            </PopoverTrigger>
            <Portal>
              <PopoverContent
                w={"max-content"}
                p={3}
                bg={brandColors.primary700}
              >
                <PopoverArrow />
                <Stack>
                  <Text color={brandColors.stroke} fontWeight={700}>
                    {trimWords(address?.toString(), 6)}
                  </Text>
                  <Btn cta={disconnect} fontSize="14px">
                    Disconnect
                  </Btn>
                </Stack>
              </PopoverContent>
            </Portal>
          </Popover>
        ) : null}
      </Flex>

      <Stack w={"100%"} overflow={"scroll"} className="hideScrolbar">
        <Flex w={"max-content"} gap={4} overflow={"scroll"} py={2} px={4}>
          {category?.map((type) => (
            <TabBtn
              isActive={sectionType === type}
              cta={() => setSectionType(type)}
            >
              {type}
            </TabBtn>
          ))}
        </Flex>
      </Stack>
      <Stack flex={1} overflow={"auto"} px={4} pb={2}>
        {sectionType == "bribe" ? (
          <BriveBox worldId={town_id as Id<"worlds">} />
        ) : (
          <Stack
            h={"100%"}
            overflow={"auto"}
            p={4}
            bg={brandColors.primary100}
            boxShadow={" 3px 3px 0px 0px rgba(30, 52, 69, 1);"}
          >
            {sectionType == "global" ? (
              <GlobalChatBox />
            ) : sectionType == "health" ? (
              <HealthBox worldId={town_id as Id<"worlds">} />
            ) : sectionType == "terminal" ? (
              <TerminalBox worldId={town_id as Id<"worlds">} />
            ) : null}
          </Stack>
        )}
      </Stack>

      <Stack pos={"relative"} px={4} pb={2}>
        {isConnected && showTipAgent && sectionType == "global" ? (
          <AgentTip worldId={town_id as Id<"worlds">} />
        ) : null}
        {isConnected &&
        sectionType == "health" &&
        selectedRevaiveItem?.title ? (
          <Revive />
        ) : null}

        {isConnected && sectionType == "global" ? (
          <Flex w={"100%"} gap={1} align={"center"}>
            <Stack flex={2}>
              <InputTeb inputvalue={inputvalue} setInputValue={setInputValue} />
            </Stack>
            <Stack flex={1}>
              <Btn cta={() => handleSend()}>send</Btn>
            </Stack>
            {!showTipAgent && sectionType == "global" ? (
              <Stack flex={1}>
                <Btn
                  color={"rgba(29, 155, 240, 1)"}
                  cta={() => setsTipAgent(true)}
                >
                  Tip
                </Btn>
              </Stack>
            ) : null}

            {/* <Button w={"max-content"} h={"100%"} size="sm" onClick={() => ""}>
              send
            </Button> */}
          </Flex>
        ) : isConnected && sectionType == "bribe" ? (
          <Stack flex={1}>
            <Btn cta={() => setsTipAgent(true)}>Pay to Bribe</Btn>
          </Stack>
        ) : null}
        {!isConnected ? <EVMConnectBTN /> : null}

        <Flex justify={"space-between"} fontFamily={"secondary"}>
          <Flex align={"center"} gap={0}>
            <Text fontSize={"sm"} fontWeight={500} textTransform={"uppercase"}>
              $host: $0.01
            </Text>
          </Flex>

          <Button variant={"text"} fontSize={"sm"} fontWeight={500}>
            Learn More{" "}
          </Button>
        </Flex>
      </Stack>
    </Flex>
  );
};

export default RightView;
