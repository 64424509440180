import React, { useState, useEffect } from "react";
import { Character } from "./Character";
import { orientationDegrees } from "../../convex/util/geometry";
import { characters } from "../../data/characters";
import { toast } from "react-toastify";
import { Player as ServerPlayer } from "../../convex/aiTown/player";
import { GameId } from "../../convex/aiTown/ids";
import { Id } from "../../convex/_generated/dataModel";
import { data as f1SpritesheetData } from "../../data/spritesheets/f1";
import { data as f2SpritesheetData } from "../../data/spritesheets/f2";
import { data as f3SpritesheetData } from "../../data/spritesheets/f3";
import { data as f4SpritesheetData } from "../../data/spritesheets/f4";
import { data as f5SpritesheetData } from "../../data/spritesheets/f5";
import { data as f6SpritesheetData } from "../../data/spritesheets/f6";
import { data as f7SpritesheetData } from "../../data/spritesheets/f7";
import { data as f8SpritesheetData } from "../../data/spritesheets/f8";
import * as amplitude from "@amplitude/analytics-browser";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { useSendInput } from "../../hooks/sendInput";
import { toastOnError } from "../../toasts";
import {
  Location,
  locationFields,
  playerLocation,
} from "../../convex/aiTown/location";
import { useHistoricalValue } from "../../../src/hooks/useHistoricalValue";
import { ServerGame } from "../../../src/hooks/serverGame";

export type SelectElement = (element?: {
  kind: "player";
  id: GameId<"players">;
  memoryView?: boolean;
}) => void;

const logged = new Set<string>();

export const Player = ({
  worldId,
  engineId,
  game,
  isViewer,
  player,
  onClick,
  historicalTime,
}: {
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  game: ServerGame;
  isViewer: boolean;
  player: ServerPlayer;

  onClick: SelectElement;
  historicalTime?: number;
}) => {
  const [characterTexture, setCharacterTexture] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const playerSpritesheetId = game.playerDescriptions.get(player.id)?.character;
  const character_id = game.playerDescriptions.get(player.id)?.character_id;
  const name = game.playerDescriptions.get(player.id)?.name;

  if (!playerSpritesheetId) {
    // throw new Error(`Player ${player.id} has no spritesheet id`);
    console.warn(`Player ${player.id} has no spritesheet id`);
  }
  // const character = characters.find((c) => c.name === playerCharacter);
  const spritesheetData = {
    f1: f1SpritesheetData,
    f2: f2SpritesheetData,
    f3: f3SpritesheetData,
    f4: f4SpritesheetData,
    f5: f5SpritesheetData,
    f6: f6SpritesheetData,
    f7: f7SpritesheetData,
    f8: f8SpritesheetData,
  };
  // const s3Url = `https://4thwall-assets-dev.s3.amazonaws.com/${character_id}/${character_id}_spritesheet.png`;
  const s3Url = `${process.env.REACT_APP_ASSETS_BUCKET}${character_id}/${character_id}_spritesheet.png`;
  const defaultTextureUrl = characters.find(
    (c) => c.name === playerSpritesheetId
  )?.textureUrl;

  const humanTokenIdentifier = useQuery(api.world.userStatus, { worldId });
  const players = [...game.world.players.values()];
  const humanPlayer = players.find((p) => p.human === humanTokenIdentifier);
  const playerConversation = game.world.playerConversation(player);
  const humanConversation =
    humanPlayer && game.world.playerConversation(humanPlayer);
  const haveInvite =
    humanPlayer &&
    humanConversation &&
    playerConversation &&
    humanConversation.id === playerConversation.id &&
    humanConversation.participants.get(humanPlayer.id)?.status.kind ===
      "invited";
  const playerStatus = playerConversation?.participants.get(player.id)?.status;
  const isMe = humanPlayer && player.id === humanPlayer.id;
  const sameConversation =
    !isMe &&
    humanConversation &&
    playerConversation &&
    humanConversation.id === playerConversation.id;
  const humanStatus =
    humanPlayer &&
    humanConversation &&
    humanConversation.participants.get(humanPlayer.id)?.status;

  // Other participants in the town
  const descriptions = useQuery(api.world.gameDescriptions, { worldId });
  const participants = playerConversation && playerConversation.participants;
  const otherParticipants =
    participants &&
    [...participants.values()].filter((p) => p.playerId !== player.id);
  const otherParticipantsIds = otherParticipants?.map((p) => p.playerId);
  const otherParticipantsNames = otherParticipantsIds?.map(
    (id) =>
      descriptions?.playerDescriptions.find((p: any) => p.playerId === id)?.name
  );
  const waitingForNearby =
    sameConversation &&
    playerStatus?.kind === "walkingOver" &&
    humanStatus?.kind === "walkingOver";
  const walkingOverTo =
    playerStatus?.kind === "walkingOver"
      ? waitingForNearby
        ? "you"
        : undefined
      : undefined;

  const isInConversation = playerStatus?.kind === "participating";
  const messages = useQuery(api.messages.listMessages, {
    worldId,
    conversationId: playerConversation?.id ?? "",
  });
  const latestMessage =
    messages && messages.length > 0
      ? messages[messages.length - 1].author === player.id
        ? messages[messages.length - 1].text
        : undefined
      : undefined;
  useEffect(() => {
    const checkS3Object = async () => {
      try {
        const response = await fetch(s3Url, { method: "HEAD" });
        if (response.ok) {
          setCharacterTexture(s3Url);
        } else {
          setCharacterTexture(defaultTextureUrl || "");
        }
      } catch (error) {
        console.error("Error checking S3 object", error);
        setCharacterTexture(defaultTextureUrl || "");
      } finally {
        setIsLoading(false);
      }
    };

    checkS3Object();
  }, [s3Url, defaultTextureUrl]);

  // ... rest of the existing code ...

  const character = {
    name: playerSpritesheetId,
    textureUrl: characterTexture,
    spritesheetData:
      spritesheetData[playerSpritesheetId as keyof typeof spritesheetData],
    speed: 0.1,
  };

  const locationBuffer = game.world.historicalLocations?.get(player.id);
  const historicalLocation = useHistoricalValue<Location>(
    locationFields,
    historicalTime,
    playerLocation(player),
    locationBuffer
  );
  if (!character) {
    if (!logged.has(playerSpritesheetId ?? "")) {
      logged.add(playerSpritesheetId ?? "");
      toast.error(`Unknown character ${playerSpritesheetId}`);
    }
    return null;
  }

  if (!historicalLocation) {
    return null;
  }

  const isSpeaking = !![...game.world.conversations.values()].find(
    (c) => c.isTyping?.playerId === player.id
  );
  const isThinking =
    !isSpeaking &&
    !![...game.world.agents.values()].find(
      (a) => a.playerId === player.id && !!a.inProgressOperation
    );
  const tileDim = game.worldMap.tileDim;
  const historicalFacing = {
    dx: historicalLocation.dx,
    dy: historicalLocation.dy,
  };

  if (isLoading || !characterTexture) {
    return null; // or a loading indicator
  }

  return (
    <>
      <Character
        name={name}
        isHuman={isMe ?? false}
        x={historicalLocation.x * tileDim + tileDim / 2}
        y={historicalLocation.y * tileDim + tileDim / 2}
        orientation={orientationDegrees(historicalFacing)}
        isMoving={historicalLocation.speed > 0}
        isThinking={isThinking}
        isSpeaking={isSpeaking}
        emoji={
          player.activity &&
          player.activity.until > (historicalTime ?? Date.now())
            ? player.activity?.emoji
            : undefined
        }
        activityDescription={
          player.activity &&
          player.activity.until > (historicalTime ?? Date.now())
            ? player.activity?.description
            : undefined
        }
        anim={player.activity?.anim}
        isInConversation={isInConversation}
        isViewer={isViewer}
        textureUrl={characterTexture || defaultTextureUrl || ""}
        spritesheetData={character.spritesheetData}
        speed={character.speed}
        haveInvite={haveInvite}
        walkingOverTo={walkingOverTo}
        latestMessage={isInConversation ? latestMessage : undefined}
        onClick={() => {
          onClick({ kind: "player", id: player.id });
        }}
      />
    </>
  );
};
