import React, { useContext, useState } from "react";
import { X, EllipsisVertical, Search } from "lucide-react";
import {
  Button,
  Input,
  Typography,
  Box,
  CircularProgress,
  FormControl,
  FormLabel,
  FormHelperText,
  List,
  ListItem,
  ListItemDecorator,
  ListItemContent,
} from "@mui/joy";
import { StyledAvatar } from "../Town/common/styledAvatar";
import MapCarousel from "./MapCarousel";
import { SelectedCharacterProps, Character } from "../../types";
import { MapName } from "../../data/maps";
import { townMaps } from "./TownPage";
import { ACTIVITIES } from "../../convex/constants";
import { PlatformContext } from "../../contexts/PlatformContext";
import AddOrEditInGameCharacter from "./AddOrEditInGameCharacter";

interface MobileSpotStudioProps {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  townName: string;
  setTownName: (name: string) => void;
  selectedCharacters: SelectedCharacterProps[];
  handleCharacterChange: (character: SelectedCharacterProps) => void;
  setSelectedCharacters: (characters: SelectedCharacterProps[]) => void;
  createGotoTown: (townName: string, mapName: MapName) => void;
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  searchResults: Character[];
  isLoading: boolean;
  addCharacterOptions: any;
  setAddCharacterOptions: (options: any) => void;
  selectedMap: { name: string; image: string; mapName: MapName };
  setSelectedMap: (map: {
    name: string;
    image: string;
    mapName: MapName;
  }) => void;
  handleCreateTown: () => void;
  removeCharacter: (characterId: string) => void;
}

const MobileSpotStudio: React.FC<MobileSpotStudioProps> = ({
  dialogOpen,
  setDialogOpen,
  townName,
  setTownName,
  selectedCharacters,
  handleCharacterChange,
  setSelectedCharacters,
  createGotoTown,
  searchTerm,
  setSearchTerm,
  searchResults,
  isLoading,
  selectedMap,
  setSelectedMap,
  handleCreateTown,
  removeCharacter,
}) => {
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const { featuredLists, NSFW, userAssets } = context;

  const [addCharacterOptions, setAddCharacterOptions] = useState<{
    open: boolean;
    character: SelectedCharacterProps;
    editing: boolean;
  }>({
    open: false,
    character: {
      character_id: "",
      identity: "",
      bio: "",
      plan: "",
      image_url: "",
      name: "",
      activities: ACTIVITIES,
    },
    editing: false,
  });

  if (!dialogOpen) return null;

  const displayList = searchTerm.trim()
    ? searchResults
    : Array.from(
        new Set(
          [
            ...featuredLists.anime,
            ...featuredLists.isekaiRPG,
            ...featuredLists.gaming,
            ...featuredLists.moviesTV,
            ...userAssets.characters,
          ].map((character) => character.character_id)
        )
      )
        .map((id) =>
          [
            ...featuredLists.anime,
            ...featuredLists.isekaiRPG,
            ...featuredLists.gaming,
            ...featuredLists.moviesTV,
            ...userAssets.characters,
          ].find((character) => character.character_id === id)
        )
        .filter(Boolean);

  return (
    <div className="fixed inset-0 z-50 bg-neutral-800 text-white overflow-auto">
      <div className="p-4">
        <div className="flex justify-between items-center mb-4">
          <Typography level="h2" className="!font-main !text-white">
            Spot Studio
          </Typography>
          <X
            size={24}
            onClick={() => setDialogOpen(false)}
            style={{ cursor: "pointer" }}
          />
        </div>

        <FormControl className="mb-4">
          {/* <FormLabel htmlFor="townName" className="text-white">
            Spot Name
          </FormLabel> */}
          <Input
            id="townName"
            placeholder="Enter your spot name"
            type="text"
            fullWidth
            variant="outlined"
            color="warning"
            className="!bg-neutral-900 !text-white !rounded-lg !border-none !w-full !my-2"
            value={townName}
            onChange={(e) => setTownName(e.target.value.slice(0, 50))}
          />
          <FormHelperText className="text-white">
            {`${townName.length}/50 characters`}
          </FormHelperText>
        </FormControl>

        <Box className="mb-4 !border-2 !border-white/20 !rounded-lg !p-2">
          <Typography className="text-orange-500 font-main text-center mb-2">
            Your Spot Members
          </Typography>
          <div className="grid grid-cols-2 gap-2 mb-4">
            {selectedCharacters.map((character) => (
              <div
                key={character.character_id}
                className="bg-neutral-700 rounded-lg p-2 relative"
              >
                <div className="flex items-center gap-2">
                  <StyledAvatar
                    alt={character.name || ""}
                    src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                    size="small"
                  />
                  <Typography className="!text-white !text-sm !font-main flex-grow truncate">
                    {character.name}
                  </Typography>
                </div>
                <EllipsisVertical
                  size={14}
                  onClick={() => {
                    setAddCharacterOptions({
                      ...addCharacterOptions,
                      character: character,
                      open: true,
                      editing: true,
                    });
                  }}
                  className="absolute bottom-1 right-1 mb-1 p-0 text-white cursor-pointer"
                />
                <X
                  size={14}
                  onClick={() => removeCharacter(character.character_id)}
                  className="absolute top-1 right-1 mb-1 p-0 text-white cursor-pointer"
                />
              </div>
            ))}
          </div>
        </Box>

        <div className="mb-4">
          <Input
            startDecorator={<Search />}
            id="searchCharacters"
            placeholder="Search Characters"
            type="search"
            fullWidth
            variant="outlined"
            value={searchTerm}
            color="warning"
            className="!bg-neutral-900 !text-white !rounded-full !border-none !w-full !my-2"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>

        {!searchTerm.trim() && (
          <h3 className="text-orange-500 text-center">Suggested Characters</h3>
        )}
        <Box
          className="mb-4 overflow-auto"
          sx={{
            height: 300,
          }}
        >
          <List
            className="!bg-neutral-900 !rounded-xl !p-2"
            sx={{
              "--ListItemDecorator-size": "56px",
              overflowY: "auto",
            }}
          >
            {isLoading ? (
              <CircularProgress size="md" />
            ) : (
              displayList.map(
                (character) =>
                  character && (
                    <ListItem
                      className="cursor-pointer !rounded-full m-1"
                      key={character.character_id}
                      onClick={() => {
                        setAddCharacterOptions({
                          open: true,
                          editing: false,
                          character: {
                            character_id: character.character_id,
                            identity: character.description || "",
                            bio: character.bio,
                            plan: "You are in 4Wall Spot, a digital world where you have the ability to walk around and interact with users and other characters.",
                            image_url: character.image_url,
                            name: character.name,
                            activities: ACTIVITIES,
                          },
                        });
                      }}
                      sx={{
                        backgroundColor: selectedCharacters.some(
                          (selectedCharacter) =>
                            selectedCharacter.character_id ===
                            character.character_id
                        )
                          ? "rgba(255, 255, 255, 0.1)"
                          : "transparent",
                      }}
                    >
                      <ListItemDecorator>
                        <StyledAvatar
                          alt={character.name}
                          size="small"
                          src={`${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture.jpg`}
                        />
                      </ListItemDecorator>
                      <ListItemContent>
                        <Typography className="!text-white !text-sm !font-main">
                          {character.name}
                        </Typography>
                      </ListItemContent>
                    </ListItem>
                  )
              )
            )}
          </List>
        </Box>

        <MapCarousel maps={townMaps} handleMapSelect={setSelectedMap} />

        <Button
          color="primary"
          onClick={handleCreateTown}
          className="!bg-fourwall-orange !rounded-lg !w-full !mt-4"
        >
          CREATE
        </Button>
      </div>

      <AddOrEditInGameCharacter
        open={addCharacterOptions.open}
        setOpen={(open) => {
          const maxCharacters = 7;
          const canAddCharacter = selectedCharacters.length < maxCharacters;

          if (open && !canAddCharacter) {
            alert(`You can only have ${maxCharacters} characters in your spot`);
          } else {
            setAddCharacterOptions({ ...addCharacterOptions, open });
          }
        }}
        character={addCharacterOptions.character}
        onAddCharacter={handleCharacterChange}
        editing={addCharacterOptions.editing}
      />
    </div>
  );
};

export default MobileSpotStudio;
