import React, { useContext } from "react";
import * as PIXI from "pixi.js";
import { useState, useEffect, useRef, useCallback } from "react";
import { AnimatedSprite, Container, Graphics, Text } from "@pixi/react";
import { NineSlicePlane } from "@pixi/react";

let animToSend;
export const Character = ({
  name,
  isHuman,
  textureUrl,
  spritesheetData,
  x,
  y,
  orientation,
  isMoving = false,
  isThinking = false,
  isSpeaking = false,
  emoji = "",
  activityDescription,
  anim = 'idle', ///
  isInConversation = false,
  isViewer = false,
  speed = 0.5,
  haveInvite = false,
  walkingOverTo,

  latestMessage,
  onClick,
}: {
  // name to show on popover
  name: string | undefined;
  // Whether this is the human player.
  isHuman: boolean;
  // Path to the texture packed image.
  textureUrl: string;
  // The data for the spritesheet.
  spritesheetData: PIXI.ISpritesheetData;
  // The pose of the NPC.
  x: number;
  y: number;
  orientation: number;
  isMoving?: boolean;
  // Shows a thought bubble if true.
  isThinking?: boolean;
  // Shows a speech bubble if true.
  isSpeaking?: boolean;
  emoji?: string;
  activityDescription?: string;
  anim?: string; ///
  isInConversation?:boolean
  // Highlights the player.
  isViewer?: boolean;
  // The speed of the animation. Can be tuned depending on the side and speed of the NPC.
  speed?: number;
  haveInvite?: boolean;
  walkingOverTo?: string | undefined;

  latestMessage?: string | undefined;
  onClick: () => void;
}) => {
  const [spriteSheet, setSpriteSheet] = useState<PIXI.Spritesheet>();
  useEffect(() => {
    const parseSheet = async () => {
      const sheet = new PIXI.Spritesheet(
        PIXI.BaseTexture.from(
          textureUrl,

          {
            scaleMode: PIXI.SCALE_MODES.NEAREST,
          }
        ),
        spritesheetData
      );
      await sheet.parse();
      setSpriteSheet(sheet);
    };
    void parseSheet();
  }, []);

  // The first "left" is "right" but reflected.
  const roundedOrientation = Math.floor(orientation / 90);
  var direction = ["right", "down", "left", "up"][roundedOrientation]; //
  //////
  // if (!isMoving){
  //   direction = "idle"+ ["right", "down", "left", "up"][roundedOrientation];
  // }
  /////
  // Prevents the animation from stopping when the texture changes
  // (see https://github.com/pixijs/pixi-react/issues/359)
  const ref = useRef<PIXI.AnimatedSprite | null>(null);
  useEffect(() => {
    // if (isMoving) {
      ref.current?.play(); /// @shrey this actually plays it
    // }
  }, [direction, isMoving]);

  if (!spriteSheet) return null;
  

  // Updating animation
  if (isMoving) {
    var anim_dir = spriteSheet.animations[direction];
    // console.log(`MOVING!`)
  }
  else{
    if(isInConversation){
      anim_dir = spriteSheet.animations['idle' + direction];
    }
    else{
      if ((anim != 'hurt') && (anim != 'dance')){
            animToSend = anim + direction;
      }
      else {
        animToSend = anim;
      }
      anim_dir = spriteSheet.animations[animToSend];
    }
     
    // else {
    //   var anim_dir = spriteSheet.animations[anim + direction]; ///
    // }
  }

  let blockOffset = { x: 0, y: 0 };
  switch (roundedOrientation) {
    case 2:
      blockOffset = { x: -20, y: 0 };
      break;
    case 0:
      blockOffset = { x: 20, y: 0 };
      break;
    case 3:
      blockOffset = { x: 0, y: -20 };
      break;
    case 1:
      blockOffset = { x: 0, y: 20 };
      break;
  }

  return (
    <Container x={x} y={y} interactive={true} cursor="pointer" sortableChildren={true}>
      {/* <div className="flex flex-row"> */}
      
      {name && (
        <Text
          x={0}
          y={-35}
          scale={0.4}
          text={name}
          anchor={{ x: 0.5, y: -4 }}
          style={
            new PIXI.TextStyle({
              fontFamily: "DM Sans, sans-serif",
              fontSize: 35,
              align: "center",
              fontWeight: "bold",
              fill: ["white"], // Main color for the text
              stroke: "black", // Outline color
              strokeThickness: 5, // Thickness of the outline
            })
          }
          sortableChildren={true}
        />
      )}

      {(emoji || activityDescription) && (
        <Container position={[0, -70]} sortableChildren={true}>
          <Graphics
            draw={(g) => {
              g.clear();
              g.lineStyle(1, 0x000000, 0.8); // Black border
              g.beginFill(0xffffff, 0.8); // White background
              g.drawRoundedRect(-40, -40, 80, 80, 15); // Larger rounded square shape
              g.endFill();
            }}
            sortableChildren={true}
          />
          {emoji && (
            <Text
              x={0}
              y={-18}
              scale={1.5} // Increased size
              text={emoji}
              anchor={{ x: 0.5, y: 0.5 }}
              sortableChildren={true}
            />
          )}
          {activityDescription && (
            <Text
              x={0}
              y={18}
              text={activityDescription}
              anchor={{ x: 0.5, y: 0.5 }}
              style={
                new PIXI.TextStyle({
                  fontFamily: "DM Sans, sans-serif",
                  fontSize: 10,
                  fontStyle: "italic",
                  fill: ["#000000"], // White text
                  wordWrap: true,
                  wordWrapWidth: 50,
                  align: "center",
                })
              }
              sortableChildren={true}
            />
          )}
        </Container>
      )}
      {/* </div> */}
      {isThinking && (
        // TODO: We'll eventually have separate assets for thinking and speech animations.
        <Text
          x={-20}
          y={-30}
          scale={{ x: -0.0, y: 0.0 }}
          text={"💭"}
          anchor={{ x: 0.5, y: 0.5 }}
          zIndex={1000}
          sortableChildren={true}
        />
      )}
      {isSpeaking && (
        // TODO: We'll eventually have separate assets for thinking and speech animations.
        <Text
          x={18}
          y={-40}
          scale={1.5}
          text={"💬"}
          anchor={{ x: 0.5, y: 0.5 }}
          zIndex={1000}
          sortableChildren={true}
        />
      )}

      {isViewer && <ViewerIndicator />}
      <AnimatedSprite
        ref={ref}
        isPlaying={isMoving}
        textures={anim_dir} // Add Idle and other animations here
        animationSpeed={speed}
        pointerdown={onClick}
        interactive
        cursor="pointer"
        anchor={{ x: 0.5, y: 0.5 }}
        zIndex={50}
        sortableChildren={true}
        scale={{ x: 1.6, y: 1.6 }}
        loop = {true}
        roundPixels = {true} // should improve performance
        // mask (PIXI.Graphics | PIXI.Sprite | null): Defines a mask for the sprite, allowing you to reveal parts of it based on the mask shape.
        //  gotoAndStop(frameNumber: number): Goes to a specific frame and stops.
        // •	gotoAndPlay(frameNumber: number): Goes to a specific frame and starts playing from there.
        // •	onComplete (() => void): Callback when the animation finishes (if it doesn’t loop).
        // •	onFrameChange ((currentFrame: number) => void): Callback when the frame changes.
        // •	onLoop (() => void): Callback for each loop cycle of the animation.
        // tint={inShadow ? 0x666666 : 0xffffff}  /// this can be used to update color if in shadow, need to update inShadow based on some criteria
        // filters={[new PIXI.filters.BlurFilter(2)]} /// This can be used to blur characters, good for focus mode
      />
      {!isHuman && (
  <>
    {haveInvite && (
      <Container position={[0, -70]} sortableChildren={true}>
        <Graphics
          draw={(g) => {
            g.clear();
            g.lineStyle(1, 0x000000, 0.8); // Black border
            g.beginFill(0xffffff, 0.8); // White background
            g.drawRoundedRect(-40, -40, 80, 80, 15); // Larger rounded square shape
            g.endFill();
          }}
          sortableChildren={true}
        />
        <Text
          x={0}
          y={-15}
          scale={1.5} // Increased size
          text={"💬"} // Emoji for chat
          anchor={{ x: 0.5, y: 0.5 }}
          sortableChildren={true}
        />
        <Text
          x={0}
          y={18}
          text={`Chat?`}
          anchor={{ x: 0.5, y: 0.5 }}
          style={
            new PIXI.TextStyle({
              fontFamily: "DM Sans, sans-serif",
              fontSize: 12,
              fontStyle: "italic",
              fill: ["#000000"], // Black text
              wordWrap: true,
              wordWrapWidth: 50,
              align: "center",
            })
          }
          sortableChildren={true}
        />
      </Container>
    )}
    {walkingOverTo && (
      <Container position={[0, -70]} sortableChildren={true}>
        <Graphics
          draw={(g) => {
            g.clear();
            g.lineStyle(1, 0x000000, 0.8); // Black border
            g.beginFill(0xffffff, 0.8); // White background
            g.drawRoundedRect(-40, -40, 80, 80, 15); // Larger rounded square shape
            g.endFill();
          }}
          sortableChildren={true}
        />
        <Text
          x={0}
          y={-15}
          scale={1.5} // Increased size
          text={"🚶"} // Emoji for walking
          anchor={{ x: 0.5, y: 0.5 }}
          sortableChildren={true}
        />
        <Text
          x={0}
          y={18}
          text={`Walking to ${walkingOverTo}`}
          anchor={{ x: 0.5, y: 0.5 }}
          style={
            new PIXI.TextStyle({
              fontFamily: "DM Sans, sans-serif",
              fontSize: 10,
              fontStyle: "italic",
              fill: ["#000000"], // Black text
              wordWrap: true,
              wordWrapWidth: 50,
              align: "center",
            })
          }
          sortableChildren={true}
        />
      </Container>
    )}
  </>
)}
      {latestMessage && (
        <Container
          position={[0, -70]}
          zIndex={10000}
          sortableChildren={true}
          interactive={true}
          cursor="pointer"
          pointerdown={onClick}
        >
          <Graphics
            draw={(g) => {
              g.clear();
              g.beginFill(0xffffff, 0.8);
              g.lineStyle(2, 0x000000, 1);
              g.drawRoundedRect(-120, -40, 240, 80, 10);
              g.endFill();
            }}
            sortableChildren={true}
          />
          <Text
            text={
              latestMessage.slice(0, 130) +
              (latestMessage.length > 130 ? "...." : "")
            }
            anchor={0.5}
            position={[0, 0]}
            style={
              new PIXI.TextStyle({
                fill: 0x000000,
                fontSize: 12,
                fontFamily: "DM Sans, sans-serif",
                wordWrap: true,
                wordWrapWidth: 230,
              })
            }
            sortableChildren={true}
          />
        </Container>
      )}
      {isViewer && (
      <Graphics
        draw={(g) => {
          g.clear();
          g.lineStyle(3, 0x69e732, 1); // Orange circle outline
          g.drawEllipse(0, 0, 24, 12);// Adjust radius for size
          g.endFill();
        }}
        zIndex={-1} // Position it below the character
        anchor={{ x: 0.5, y: 0.5 }}
        position={[0, 41]}
      />
    )}
    </Container>
  );
};

function ViewerIndicator() {
  const draw = useCallback((g: any) => {
    g.clear();
    g.beginFill(0xffff0b, 0.5);
    g.drawRoundedRect(-10, 10, 20, 10, 100);
    g.endFill();
  }, []);

  return <Graphics draw={draw} />;
}


