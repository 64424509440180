import { Grid, Stack, Text, Select } from "@chakra-ui/react";
import { assets } from "../../../assets";
import { useState } from "react";
import BriveCard from "./BriveCard";
import { useServerGame } from "../../../../../hooks/serverGame";
import { Id } from "../../../../../convex/_generated/dataModel";
import { useInfluenceActions } from "../../../../Town/hooks/useInfluenceActions";
import SelectBox from "../../Input/SelectBox";

const BriveBox = ({ worldId }: { worldId?: Id<"worlds"> }) => {
  console.log("worldId", worldId);
  const Cards = [
    {
      id: 1001,
      image: assets.LOGOS.logo,
      tittle: "KICK",
      amount: "200k $POD",
    },
    {
      id: 1002,

      image: assets.LOGOS.logo,
      tittle: "ATTACK",
      amount: "200k $POD",
    },
    {
      id: 1003,

      image: assets.LOGOS.logo,
      tittle: "DANCE",
      amount: "200k $POD",
    },
    {
      id: 1004,

      image: assets.LOGOS.logo,
      tittle: "INFLUENCE",
      amount: "200k $POD",
    },
  ];

  const [card, setCard] = useState<any>(null);
  const [selectedFromAgent, setSelectedFromAgent] = useState<string>("");
  const [selectedToAgent, setSelectedToAgent] = useState<string>("");
  const { handleAttack, handleDance, handleInfluence } = useInfluenceActions();

  const game = useServerGame(worldId);
  const playerDescriptions = game?.playerDescriptions;
  const agent = game?.world.agents;

  const playerList = Array.from(game?.world.players.values() ?? [])
    .filter((player) => !player.human)
    .map((player) => {
      const matchingAgent = [...(agent?.values() ?? [])].find(
        (a) => a.playerId === player.id
      );
      return {
        title: playerDescriptions?.get(player.id)?.name,
        playerId: player.id,
        agentId: matchingAgent?.id,
      };
    });

  const handleCardClick = (item: any) => {
    const fromAgent = playerList.find((p) => p.title === selectedFromAgent);
    const toAgent = playerList.find((p) => p.title === selectedToAgent);

    if (!fromAgent || !toAgent || !worldId) return;

    switch (item.id) {
      case 1001:
        // KICK action - implement if needed
        break;
      case 1002:
        handleAttack({
          worldId,
          playerId: fromAgent.playerId,
          otherPlayerId: toAgent.playerId,
          attackerAgentId: fromAgent.agentId,
          victimAgentId: toAgent.agentId,
        });
        break;
      case 1003:
        handleDance({
          worldId,
          playerId: fromAgent.playerId,
          agentId: fromAgent.agentId ?? "",
        });
        break;
      case 1004:
        handleInfluence({
          worldId,
          playerId: fromAgent.playerId,
          agentId: fromAgent.agentId ?? "",
          inceptMemory: "test",
        });
        break;
    }
  };

  return (
    <Stack gap={4}>
      <Grid templateColumns="repeat(2, 1fr)" gap={6} rowGap={8}>
        <Stack>
          <Text fontWeight={800}>Bribe to</Text>
          <SelectBox
            placeholder={"From Agent"}
            align="center"
            onChange={(e: any) => setSelectedFromAgent(e.target.value)}
          >
            {playerList?.map((item) => (
              <option key={item.playerId} value={item.title}>
                {item.title}
              </option>
            ))}
          </SelectBox>
        </Stack>
        <Stack>
          <Text fontWeight={800}>Action to</Text>
          <SelectBox
            placeholder={"To Agent"}
            align="center"
            onChange={(e: any) => setSelectedToAgent(e.target.value)}
          >
            {playerList?.map((item) => (
              <option key={item.playerId} value={item.title}>
                {item.title}
              </option>
            ))}
          </SelectBox>
        </Stack>
        {Cards?.map((item) => (
          <BriveCard
            item={item}
            card={card}
            setCard={() => {
              handleCardClick(item);
              setCard(item);
            }}
          />
        ))}
      </Grid>
    </Stack>
  );
};

export default BriveBox;
