import { Flex, Stack, Text } from "@chakra-ui/react";
import { useAppCtx } from "../../../contexts/app.context";
import { brandColors } from "../../../theme/app.theme";
import Btn from "../../Buttons/Btn";
import InputGropedTab from "../../Input/InputGropedTab";
import SelectBox from "../../Input/SelectBox";
import { Id } from "../../../../../convex/_generated/dataModel";
import { useServerGame } from "../../../../../hooks/serverGame";

const AgentTip = ({ worldId }: { worldId: Id<"worlds"> }) => {
  const { showTipAgent, setsTipAgent } = useAppCtx();
  const game = useServerGame(worldId);
  const playerDescriptions = game?.playerDescriptions;
  const agent = game?.world.agents;

  const playerList = Array.from(game?.world.players.values() ?? [])
    .filter((player) => !player.human)
    .map((player) => {
      const matchingAgent = [...(agent?.values() ?? [])].find(
        (a) => a.playerId === player.id
      );
      return {
        title: playerDescriptions?.get(player.id)?.name,
        playerId: player.id,
        agentId: matchingAgent?.id,
      };
    });

  return (
    <Stack
      w={"100%"}
      bg={brandColors.primary100}
      zIndex={1}
      top={"-150px"}
      left={0}
      border={`1px solid ${brandColors.stroke}`}
      p={4}
      boxShadow={" 3px 3px 0px 0px rgba(30, 52, 69, 1);"}
      visibility={showTipAgent ? "visible" : "hidden"}
    >
      <Text fontWeight={800}>Tip the agent with $host</Text>

      <Flex gap={2}>
        <SelectBox>
          {playerList.map((player) => (
            <option key={player.playerId} value={player.playerId}>
              {player.title}
            </option>
          ))}
        </SelectBox>
        <InputGropedTab />
      </Flex>

      <Flex gap={2}>
        <Stack flex={1}>
          <Btn color={"rgba(29, 155, 240, 1)"} cta={() => setsTipAgent(false)}>
            cancel
          </Btn>
        </Stack>
        <Stack flex={1}>
          <Btn
          // cta={() => setsTipAgent(true)}
          >
            send
          </Btn>
        </Stack>
      </Flex>
    </Stack>
  );
};

export default AgentTip;
