import React, { useEffect, useState, useContext } from "react";
import TownCard from "./TownCard";
import { useMutation } from "convex/react";
import { api } from "../../convex/_generated/api";
import useRequest from "../../apis/useRequest";
import { Town } from "../../types";
import { useNavigate } from "react-router-dom";
import { CSSProperties } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress } from "@mui/material";
import { Plus } from "lucide-react";

import { SocialIcon } from "react-social-icons";
import {
  createTown,
  getTowns,
  getTownCharacterData,
  addToWaitlist,
  getIfUserInWaitlist,
  auxLLM,
} from "../../apis/request";

import { PlatformContext } from "../../contexts/PlatformContext";
import CreateTownDialog from "./SpotStudio";
import {
  Modal,
  ModalDialog,
  Card,
  CardContent,
  Button,
  Chip,
  ModalClose,
} from "@mui/joy";
import { MapName } from "../../data/maps";
import { X } from "lucide-react";
import { SelectedCharacterProps } from "../../types";
import Welcome from "./Welcome";

const sampleTown = {
  name: "Sample - Jujitsu High",
  map: "dojo" as MapName,
  character_ids_plans: [
    {
      character_id: "f9937690-21f4-4678-b187-d7d4252fead3",
      plan: "Infiltrate Jujutsu High to assassinate Satoru Gojo, exploiting any weaknesses in the school's defenses.",
      activities: [
        { description: "Sharpening Tools", emoji: "🔪", until: 60_000 },
        { description: "Stealth Training", emoji: "🥷", until: 60_000 },
        {
          description: "Studying Jujutsu High Layout",
          emoji: "🗺️",
          until: 60_000,
        },
      ],
    }, // toji
    {
      character_id: "c78a6200-450f-43e3-943b-946aa7fda660",
      plan: "Protect the students and prepare defenses against the impending threats, while staying vigilant for Toji's assassination attempt.",
      activities: [
        { description: "Reinforcing Barriers", emoji: "🛡️", until: 60_000 },
        {
          description: "Teaching Advanced Techniques",
          emoji: "📚",
          until: 60_000,
        },
        {
          description: "Patrolling School Grounds",
          emoji: "👁️",
          until: 60_000,
        },
      ],
    }, // gojo
    {
      character_id: "2bf85973-ebb9-4322-b17d-387bfc5aa93e",
      plan: "Manipulate events to force a confrontation with Yuji, aiming to fully possess his vessel and regain full power.",
      activities: [
        { description: "Corrupting Cursed Energy", emoji: "⚫", until: 60_000 },
        { description: "Plotting Chaos", emoji: "🌪️", until: 60_000 },
        { description: "Strengthening Possession", emoji: "👥", until: 60_000 },
      ],
    }, // sukuna
    {
      character_id: "ad19a77b-3d77-408d-ad46-44ab6b29da89",
      plan: "Struggle to maintain control as Sukuna attempts to take over, while trying to protect his friends and the school from the brewing conflict.",
      activities: [
        { description: "Fighting Random Curses", emoji: "👊", until: 60_000 },
        { description: "Resistance Training", emoji: "🏋️", until: 60_000 },
        { description: "Cursed Energy Control", emoji: "🔮", until: 60_000 },
      ],
    }, // yuji
    {
      character_id: "a81cb2ab-3d55-4b43-9514-57841dfd9d11",
      plan: "Support Yuji in his fight against Sukuna's influence, while preparing to defend Jujutsu High from both internal and external threats.",
      activities: [
        { description: "Practicing Shikigami", emoji: "🐺", until: 60_000 },
        {
          description: "Developing New Techniques",
          emoji: "💡",
          until: 60_000,
        },
        { description: "Scouting for Threats", emoji: "🔭", until: 60_000 },
      ],
    }, // megumi
  ],
};

export const buildCharacterData = (characters: any) => {
  const characterIds = ["f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8"];

  return characters.map((character: any, index: number) => {
    return {
      name: character.name,
      identity: character.description,
      bio: character.bio,
      plan: character.plan,
      image: `${process.env.REACT_APP_ASSETS_BUCKET}${character.character_id}/display_picture`,
      character: characterIds[index % characterIds.length],
      character_id: character.character_id,
      activities: character.activities,
    };
  });
};

const PLAYAI_ALLOWED_USERS = [
  "gokul8967",
  "viswa123",
  "joemama",
  "shreyko",
  "ramz",
];

export const townMaps: {
  name: string;
  image: string;
  mapName: MapName;
  mapPrompt: string;
  mapAudioUrl: string;
}[] = [
  {
    name: "Halloween Town",
    image: "/halloween.png",
    mapName: "halloween",
    mapPrompt:
      "Your world is restricted to a spooky courtyard adorned with glowing jack-o'-lanterns, dark red banners, and Halloween decorations. An ominous building with a giant carved pumpkin face looms at the center, its fiery eyes and mouth lighting up the entrance. Ghosts and skeletons are scattered around the area, with trees dressed in orange autumn leaves, adding to the eerie atmosphere. You should be aware of this area as a haunted zone filled with supernatural energy and strange creatures.",
    mapAudioUrl: "/halloween.mp3",
  },

  {
    name: "PlayAI",
    image: "/playai.png",
    mapName: "playai",
    mapPrompt:
      "You stand in ‘The Grand Arena,’ an imposing coliseum where ancient stone seats surround a central battleground. Above, colorful banners symbolize the presence of iconic crypto meme coins—Mog, Giga, Popcat, Harry Potter Obama Sonic 10, spx, Brett, and Retardio. In the center, a large brazier burns brightly, casting a warm light on scattered weapons and shields, remnants of fierce clashes. Skulls rest ominously in the shadows, a reminder of past battles fought here. A blood-red banner overhead boldly asks, ‘Who’s the Top Dawg?’ signaling a challenge to prove your worth. This arena is a place of trials and triumph, where only the strongest prevail.",
    mapAudioUrl: "/halloween.mp3",
  },
  {
    name: "Camp Spot",
    image: "/camp.png",
    mapName: "camp",
    mapPrompt:
      "Your world is restricted to a cozy forest campsite with two tents surrounded by towering trees. A campfire burns in the center, with supplies and tools scattered nearby. A parked car rests on the right side, and a stone furnace sits next to the tent on the right. The spot is lively with nature, including small animals like a deer hidden in the forest. You should be familiar with this as a safe resting spot in the wilderness. The camp is called 'Camp Spot'.",
    mapAudioUrl: "/camp.mp3",
  },
  {
    name: "Carnival",
    image: "/carnival.png",
    mapName: "carnival",
    mapPrompt:
      "Your world is restricted to a vibrant carnival scene with a large, colorful carousel at its center, adorned with ornate designs and lit by small twinkling lights. Tents and booths surround the area, suggesting attractions, games, and food stalls. The lively atmosphere is ideal for entertainment and fun. You should know this as a bustling carnival space where visitors come to enjoy rides, games, and festivities.",
    mapAudioUrl: "/carnival.mp3",
  },
  {
    name: "Dojo",
    image: "/dojo.png",
    mapName: "dojo",
    mapPrompt:
      "Your world is restricted to a secluded dojo nestled within rocky cliffs. The entrance is marked by traditional architecture with green-tiled roofs and red banners. A large golden dragon is emblazoned on a red rug in front of the building, symbolizing strength and discipline. You should recognize this location as a place for martial arts training, where mastery of combat and inner focus are honed.",
    mapAudioUrl: "/dojo.mp3",
  },

  {
    name: "The Iceberg",
    image: "/igloo.png",
    mapName: "igloo",
    mapPrompt:
      "Your world is restricted to a cold, icy landscape with an igloo situated on the snow-covered ground near a frozen body of water. A small snowman and various tools are scattered outside the igloo, while a polar bear and penguins can be seen in the background near icy cliffs. You should recognize this world as a remote, frigid environment where survival depends on warmth and caution around wildlife.",
    mapAudioUrl: "/iceberg.mp3",
  },
  {
    name: "Startup Lab",
    image: "/startup.png",
    mapName: "startupLab",
    mapPrompt:
      "You are in the heart of a bustling startup office, a workspace filled with creativity, collaboration, and a hint of organized chaos. Rows of desks are lined with laptops, notebooks, and scattered gadgets, while the walls hold shelves stacked with books, files, and odd trinkets. A large screen displays data visualizations, indicating active projects and progress updates. The ‘EXIT’ sign points to the only door, but your focus is drawn to the assortment of tech equipment and quirky decorations that make this space feel alive. Characters here are tech-savvy, driven, and always on the edge of innovation. This office is known as ‘The Hub,’ a place where ideas are born and dreams are built.",
    mapAudioUrl: "/dojo.mp3",
  },
];

function TownPage() {
  const [selectedWorldId, setSelectedWorldId] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [townName, setTownName] = useState("");
  const [isPlanGeneratingId, setIsPlanGeneratingId] = useState<string | null>(
    null
  );
  const [selectedCharacters, setSelectedCharacters] = useState<
    SelectedCharacterProps[]
  >([]);

  const [isWaitlistLoading, setIsWaitlistLoading] = useState(false);
  const [welcomeOpen, setWelcomeOpen] = useState(false);
  const [towns, setTowns] = useState<Town[]>([]);
  const [loadingTowns, setLoadingTowns] = useState(false);
  const [isInWaitlist, setIsInWaitlist] = useState(false);
  const { user, isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  const createNewWorld = useMutation(api.world.createNewWorld);
  const makeRequest = useRequest();
  const context = useContext(PlatformContext);
  if (!context) {
    throw new Error(
      "useContext must be used within a PlatformContext.Provider"
    );
  }
  const { username, isAlphaTester, socialUrls } = context;

  const fetchUserTowns = async () => {
    setLoadingTowns(true);
    const response = await makeRequest<Town[], void>(getTowns(username));
    setTowns(response);
    setLoadingTowns(false);
  };
  const checkIfUserInWaitlist = async () => {
    const response = await makeRequest<any, void>(
      getIfUserInWaitlist(username)
    );

    setIsInWaitlist(response.in_waitlist);
  };
  useEffect(() => {
    if (isAlphaTester) {
      fetchUserTowns();
    } else {
      checkIfUserInWaitlist();
    }
  }, [username]);

  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("hasVisitedSpotPage");
    if (
      (hasVisitedBefore === "false" || !hasVisitedBefore) &&
      towns.length === 0 &&
      isAlphaTester
    ) {
      setWelcomeOpen(true);
      localStorage.setItem("hasVisitedSpotPage", "true");
    } else {
      setWelcomeOpen(false);
    }
  }, [username, towns]);

  if (isLoading || loadingTowns)
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  if (!isAuthenticated) {
    loginWithRedirect({
      appState: { returnTo: "/spot" },
    });
  }
  const createTownOnBackend = async (townBody: Town) => {
    const response = await makeRequest<void, Town>(
      createTown(username),
      townBody
    );
  };

  const createGotoTown = async (town_name: string, mapName: MapName) => {
    const characterData = await makeRequest<any, void>(
      getTownCharacterData(
        selectedCharacters.map((c) => c.character_id).join(",")
      )
    );
    for (const character of characterData) {
      if (!character.plan) {
        character.plan = selectedCharacters.find(
          (c) => c.character_id === character.character_id
        )?.plan;
      }
      if (!character.activities || character.activities.length === 0) {
        character.activities =
          selectedCharacters.find(
            (c) => c.character_id === character.character_id
          )?.activities || [];
      }
    }

    const townCharacterData = buildCharacterData(characterData);
    const map = townMaps.find((townMap) => townMap.mapName === mapName);
    if (!map) {
      throw new Error(`No map for name: ${mapName}`);
    }

    let worldStatus = await createNewWorld({
      map: {
        mapName: mapName,
        mapPrompt:
          townMaps.find((townMap) => townMap.mapName === mapName)?.mapPrompt ||
          "",
        mapAudioUrl:
          townMaps.find((townMap) => townMap.mapName === mapName)
            ?.mapAudioUrl || "",
      },
      characters: townCharacterData,
    });

    const awsCharacterData: SelectedCharacterProps[] = [];
    for (const character of townCharacterData) {
      awsCharacterData.push({
        name: character.name,
        character_id: character.character_id,
        plan: character.plan,
        identity: character.identity,
        bio: character.bio,
        image_url: character.image,
        activities: character.activities,
      });
    }
    createTownOnBackend({
      town_name: town_name,
      town_id: worldStatus.worldId,
      username: username,
      map: mapName,
      characters: awsCharacterData,
    });
    setSelectedWorldId(worldStatus.worldId);

    navigate(`/spot/${worldStatus.worldId}`, {
      state: { townName: town_name, charactersMetadata: awsCharacterData },
    });
  };

  const handleCharacterChange = async (character: SelectedCharacterProps) => {
    setSelectedCharacters((prev) => {
      const existingIndex = prev.findIndex(
        (ch) => ch.character_id === character.character_id
      );
      if (existingIndex !== -1) {
        // Update existing character
        const updatedCharacters = [...prev];
        updatedCharacters[existingIndex] = character;
        return updatedCharacters;
      } else if (prev.length < 7) {
        // Add new character
        return [...prev, character];
      } else {
        alert("Please choose at most 7 characters.");
        return prev;
      }
    });
  };

  const handleJoinWaitlist = async () => {
    setIsWaitlistLoading(true);
    try {
      const response = await makeRequest(addToWaitlist(username));
      setIsInWaitlist(true);
    } catch (error) {
      console.error("Error joining waitlist:", error);
    }

    setIsWaitlistLoading(false);
  };
  const handleWelcomeClose = async () => {
    const characterData = await makeRequest<any, void>(
      getTownCharacterData(
        sampleTown.character_ids_plans
          .map((char) => char.character_id)
          .join(",")
      )
    );

    for (const character of characterData) {
      character.plan = sampleTown.character_ids_plans.find(
        (c) => c.character_id === character.character_id
      )?.plan;
      character.activities =
        sampleTown.character_ids_plans.find(
          (c) => c.character_id === character.character_id
        )?.activities || [];
    }

    const townCharacterData = buildCharacterData(characterData);
    const awsCharacterData: SelectedCharacterProps[] = [];
    for (const character of townCharacterData) {
      awsCharacterData.push({
        name: character.name,
        character_id: character.character_id,
        plan: character.plan,
        identity: character.identity,
        bio: character.bio,
        image_url: character.image,
        activities: character.activities,
      });
    }
    const map = townMaps.find((townMap) => townMap.mapName === sampleTown.map);
    if (!map) {
      throw new Error(`No map for name: ${sampleTown.map}`);
    }

    let worldStatus = await createNewWorld({
      map: {
        mapName: sampleTown.map,
        mapPrompt:
          townMaps.find((townMap) => townMap.mapName === sampleTown.map)
            ?.mapPrompt || "",
        mapAudioUrl:
          townMaps.find((townMap) => townMap.mapName === sampleTown.map)
            ?.mapAudioUrl || "",
      },
      characters: townCharacterData,
    });

    createTownOnBackend({
      town_name: sampleTown.name,
      town_id: worldStatus.worldId,
      username: username,
      map: sampleTown.map,
      characters: awsCharacterData,
    });
    setSelectedWorldId(worldStatus.worldId);
    navigate(`/spot/${worldStatus.worldId}`, {
      state: {
        townName: sampleTown.name,
        charactersMetadata: awsCharacterData,
      },
    });

    setWelcomeOpen(false);
  };

  if (!isAlphaTester) {
    return (
      <div
        className="flex flex-col items-center justify-center h-screen w-full bg-cover bg-center"
        style={{
          backgroundImage:
            "linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('/spotsbg.png')",
        }}
      >
        <div className="flex flex-col justify-center items-center space-y-7">
          <img
            src="/Spot.svg"
            alt="4WALL SPOTS"
            className="w-4/5 items-center justify-center"
          />
          <p className="text-white text-lg font-main m-0 w-1/2 text-center">
            Hang out with your friends and AI characters in virtual worlds.
          </p>

          {isInWaitlist ? (
            <Chip
              size="lg"
              color="warning"
              className="!bg-orange-500 !text-white  !font-main !font-bold"
            >
              You are on the waitlist!
            </Chip>
          ) : (
            <Button
              variant="outlined"
              size="lg"
              className="!transition-all !duration-200 !text-fourwall-orange !border-fourwall-orange !font-bold !font-main  !rounded-full hover:!bg-fourwall-orange hover:!text-white "
              onClick={handleJoinWaitlist}
              disabled={isWaitlistLoading}
            >
              {isWaitlistLoading ? (
                <CircularProgress className="!text-fourwall-orange" />
              ) : (
                "JOIN WAITLIST"
              )}
            </Button>
          )}
        </div>
        <div className="flex flex-col items-center justify-center m-10">
          <p className="text-white text-sm font-main m-0 w-full text-center">
            Follow us on socials to stay up to date.
          </p>
          <div className="flex flex-row  space-x-4">
            <SocialIcon
              url={socialUrls.Discord}
              target="_blank"
              style={styles.socialIconStyle}
              fgColor="var(--orange-brand-accent)"
              bgColor="transparent"
            />
            <SocialIcon
              url={socialUrls.Reddit}
              target="_blank"
              style={styles.socialIconStyle}
              fgColor="var(--orange-brand-accent)"
              bgColor="transparent"
            />
            <SocialIcon
              url={socialUrls.X}
              target="_blank"
              style={styles.socialIconStyle}
              fgColor="var(--orange-brand-accent)"
              bgColor="transparent"
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="flex flex-col items-left min-h-screen w-full bg-cover bg-center bg-fixed"
      style={{
        backgroundImage:
          "linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url('/spotsbg.png')",
      }}
    >
      {isAuthenticated && user ? (
        <div className="flex flex-col p-2 items-center justify-center mt-20">
          <div className="flex justify-center items-center">
            <img src="/yourspots.svg" alt="4WALL SPOTS" className="w-full" />
          </div>

          <CreateTownDialog
            dialogOpen={dialogOpen}
            setDialogOpen={setDialogOpen}
            townName={townName}
            setTownName={setTownName}
            selectedCharacters={selectedCharacters}
            handleCharacterChange={handleCharacterChange}
            setSelectedCharacters={setSelectedCharacters}
            createGotoTown={createGotoTown}
          />

          <div className=" flex flex-col items-center p-4 m-0 rounded-xl">
            <Button
              variant="outlined"
              size="lg"
              className="!transition-all !duration-200 !text-fourwall-orange !border-fourwall-orange !font-bold !font-main  !rounded-full hover:!bg-fourwall-orange hover:!text-white !m-2"
              onClick={() => setDialogOpen(true)}
            >
              Create New Spot
            </Button>
            <div className="flex flex-row flex-wrap items-left gap-1">
              {/* <Card
                onClick={() => setDialogOpen(true)}
                className="!bg-neutral-800 !rounded-xl flex mr-1 p-4 h-[18rem] w-[19rem] !border-2 !border-solid !border-fourwall-orange !shadow-lg !shadow-neutral-800/50 !text-orange-500 hover:cursor-pointer hover:!bg-neutral-700 transition-all duration-200"
              >
                <CardContent className="flex flex-col justify-center items-center">
                  <Plus size={50} color="var(--orange-brand-accent)" />
                </CardContent>
              </Card> */}

              {towns.map((town) => (
                <TownCard
                  key={town.town_id}
                  townName={town.town_name}
                  townId={town.town_id}
                  characters={town.characters}
                  map={town.map}
                  onClick={() =>
                    navigate(`/spot/${town.town_id}`, {
                      state: { townName: town.town_name },
                    })
                  }
                  refresh={fetchUserTowns}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <h1 style={styles.heading}>Please login to access 4Wall Spot</h1>
      )}
      <Welcome open={welcomeOpen} onClose={handleWelcomeClose} />
    </div>
  );
}

const styles: { [key: string]: CSSProperties } = {
  heading: {
    textAlign: "center",
    fontFamily: "var(--font_b)",

    color: "var(--primary-text-color)", // You can replace "black" with any color you need
  },
  gridContainer: {
    margin: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "left",

    borderRadius: "0.5em",
    padding: "1rem",
  },
  townsHeading: {
    textAlign: "left",
    color: "white",
    borderBottom: "1px solid var(--orange-brand-accent)",
    paddingBottom: "0.5rem",
  },
  createButton: {
    backgroundColor: "var(--orange-brand-accent)",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    fontFamily: "var(--font_b)",
    margin: "1rem auto ",
  },
};
export default TownPage;
