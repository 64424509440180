import React from "react";
import { PixiComponent, applyDefaultProps } from "@pixi/react";
import * as PIXI from "pixi.js";
import { AnimatedSprite, WorldMap } from "../../convex/aiTown/worldMap";
import * as campfire from "../../data/animations/campfire.json";
import * as gentlewaterfall from "../../data/animations/gentlewaterfall.json";
import * as gentlesparkle from "../../data/animations/gentlesparkle.json";
import * as gentlesplash from "../../data/animations/gentlesplash.json";
import * as windmill from "../../data/animations/windmill.json";
import * as bat from "../../data/animations/bat.json";
import * as ghost from "../../data/animations/ghost.json";
import * as ghostTurn from "../../data/animations/ghostturn.json";
import * as smworm from "../../data/animations/smworm.json";
import * as pumpkin from "../../data/animations/pumpkin.json";
import * as fire from "../../data/animations/fire.json";
import * as smoke from "../../data/animations/smoke.json";
import * as smoke1 from "../../data/animations/smoke1.json";
import * as tornado from "../../data/animations/tornado.json";
import * as ice from "../../data/animations/ice.json";

const animations = {
  "campfire.json": {
    spritesheet: campfire,
    url: "/spritesheets/campfire.png",
  },
  "gentlesparkle.json": {
    spritesheet: gentlesparkle,
    url: "/spritesheets/gentlesparkle32.png",
  },
  "gentlewaterfall.json": {
    spritesheet: gentlewaterfall,
    url: "/spritesheets/gentlewaterfall32.png",
  },
  "windmill.json": {
    spritesheet: windmill,
    url: "/spritesheets/windmill.png",
  },
  "gentlesplash.json": {
    spritesheet: gentlesplash,
    url: "/spritesheets/gentlewaterfall32.png",
  },
  "bat.json": {
    spritesheet: bat,
    url: "/spritesheets/bat.png",
  },
  "ghost.json": {
    spritesheet: ghost,
    url: "/spritesheets/ghost.png",
  },
  "ghostturn.json": {
    spritesheet: ghostTurn,
    url: "/spritesheets/ghost.png",
  },
  "smworm.json": {
    spritesheet: smworm,
    url: "/spritesheets/small_worm.png",
  },
  "pumpkin.json": {
    spritesheet: pumpkin,
    url: "/spritesheets/pumpking.png",
  },
  "fire.json": {
    spritesheet: fire,
    url: "/spritesheets/fire.png",
  },
  "smoke.json": {
    spritesheet: smoke,
    url: "/spritesheets/smoke.png",
  },
  "smoke1.json": {
    spritesheet: smoke1,
    url: "/spritesheets/smoke.png",
  },
  "tornado.json": {
    spritesheet: tornado,
    url: "/spritesheets/tornado.png",
  },
  "ice.json": {
    spritesheet: ice,
    url: "/spritesheets/ice.png",
  },
};

export const PixiStaticMap = PixiComponent("StaticMap", {
  create: (props: { map: WorldMap; [k: string]: any }) => {
    PIXI.utils.clearTextureCache();
    const map = props.map;

    // map.tileSetUrl = "/gentle-obj.png";
    const numxtiles = Math.floor(map.tileSetDimX / map.tileDim);
    const numytiles = Math.floor(map.tileSetDimY / map.tileDim);

    const bt = PIXI.BaseTexture.from(map.tileSetUrl, {
      scaleMode: PIXI.SCALE_MODES.NEAREST,
    });

    const tiles = [];
    for (let x = 0; x < numxtiles; x++) {
      for (let y = 0; y < numytiles; y++) {
        tiles[x + y * numxtiles] = new PIXI.Texture(
          bt,
          new PIXI.Rectangle(
            x * map.tileDim,
            y * map.tileDim,
            map.tileDim,
            map.tileDim
          )
        );
      }
    }
    const screenxtiles = map.bgTiles[0].length;
    const screenytiles = map.bgTiles[0][0].length;

    const container = new PIXI.Container();
    const allLayers = [...map.bgTiles, ...map.objectTiles];

    // blit bg & object layers of map onto canvas
    for (let i = 0; i < screenxtiles * screenytiles; i++) {
      const x = i % screenxtiles;
      const y = Math.floor(i / screenxtiles);
      const xPx = x * map.tileDim;
      const yPx = y * map.tileDim;

      // Add all layers of backgrounds.
      for (const layer of allLayers) {
        const tileIndex = layer[x][y];
        // Some layers may not have tiles at this location.
        if (tileIndex === -1) continue;
        const ctile = new PIXI.Sprite(tiles[tileIndex]);
        ctile.x = xPx;
        ctile.y = yPx;
        container.addChild(ctile);
      }
    }

    // TODO: Add layers.
    const spritesBySheet = new Map<string, AnimatedSprite[]>();
    for (const sprite of map.animatedSprites) {
      const sheet = sprite.sheet;
      if (!spritesBySheet.has(sheet)) {
        spritesBySheet.set(sheet, []);
      }
      spritesBySheet.get(sheet)!.push(sprite);
    }
    console.log("SPRITE_BY_SHEET", spritesBySheet);
    for (const [sheet, sprites] of spritesBySheet.entries()) {
      const animation = (animations as any)[sheet];
      if (sheet === "gentlesparkle.json") {
        continue;
      }
      if (!animation) {
        console.error("Could not find animation", sheet);
        continue;
      }
      const { spritesheet, url } = animation;
      const texture = PIXI.BaseTexture.from(url, {
        scaleMode: PIXI.SCALE_MODES.NEAREST,
      });
      const spriteSheet = new PIXI.Spritesheet(texture, spritesheet);
      spriteSheet.parse().then(() => {
        for (const sprite of sprites) {
          const pixiAnimation = spriteSheet.animations[sprite.animation];
          if (!pixiAnimation) {
            console.error("Failed to load animation", sprite);
            continue;
          }
          const pixiSprite = new PIXI.AnimatedSprite(pixiAnimation);
          pixiSprite.animationSpeed = 0.1;
          pixiSprite.autoUpdate = true;
          pixiSprite.x = sprite.x;
          pixiSprite.y = sprite.y;
          pixiSprite.width = sprite.w;
          pixiSprite.height = sprite.h;
          container.addChild(pixiSprite);
          pixiSprite.play();
        }
      });
    }

    container.x = 0;
    container.y = 0;

    // Set the hit area manually to ensure `pointerdown` events are delivered to this container.
    container.interactive = true;
    container.hitArea = new PIXI.Rectangle(
      0,
      0,
      screenxtiles * map.tileDim,
      screenytiles * map.tileDim
    );

    return container;
  },

  applyProps: (instance, oldProps, newProps) => {
    applyDefaultProps(instance, oldProps, newProps);
  },
});
